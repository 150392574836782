import { Installation, useSession } from "@seluxit/wappsto-porcelain";
import { Col, Row, Typography } from "antd";
import React from "react";
import { useEffect } from "react";
import InstallationCard from "../components/InstallationCard";
import Authentication from "../core/features/authentication";
import { useTranslation } from "../translations";

const termsLink = "https://www.seluxit.com/seluxit-cloud-terms";

const ListInstallations = () => {
  const { sendAsync, items, loading } = Installation.useList();
  const validSession = useSession();
  const { tC } = useTranslation();

  useEffect(() => {
    if (validSession) {
      sendAsync({});
    }
  }, [sendAsync, validSession]);

  if (!validSession) {
    return (
      <>
        <h1>{tC("installations.login")}</h1>
        <Authentication registrationLink="/register" termsLink={termsLink} />
      </>
    );
  }

  if (loading) {
    return <p>{tC("installations.loading")}</p>;
  }

  if (!items || items.length === 0) {
    return <p>{tC("installations.noWapps")}</p>;
  }

  return (
    <>
      <Typography.Title level={1}>{tC("installations.title")}</Typography.Title>
      <Row gutter={[16, 24]}>
        {items.map((installation) => {
          if (typeof installation === "string") {
            return <p key={installation}>{installation}</p>;
          }
          return (
            <Col span={4} key={installation.meta.id}>
              <InstallationCard installation={installation} />
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default ListInstallations;
