import { JSONValue } from "../../../../utils/types";
import ca from "./ca/authentication.json";
import da from "./da/authentication.json";
import de from "./de/authentication.json";
import en from "./en/authentication.json";
import es from "./es/authentication.json";

const authentication: Record<string, JSONValue> = { ca, da, en, es, de };

export default authentication;
