import { Dto } from "@seluxit/wappsto-porcelain";
import React from "react";
import AppIcon from "../../../../components/AppIcon";
import GenericNotification from "./GenericNotification";

type InstallationMessageType = {
  notification: Dto.NotificationOutput;
  onClick: () => void;
};

function InstallationMessage({
  notification,
  onClick,
}: InstallationMessageType) {
  if (notification.custom === undefined || notification.base === undefined) {
    return null;
  }
  const title: string =
    notification.custom.title_installation ||
    notification.custom.name_installation ||
    notification.base.from_name ||
    "";
  const description =
    (notification.custom?.message && notification.custom.message) || "";

  return (
    <GenericNotification
      icon={<AppIcon id={notification.base.from} width={30} />}
      title={title}
      description={description}
      notification={notification}
      onClick={onClick}
    />
  );
}

export default InstallationMessage;
