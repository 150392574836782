import { Dto, Permission } from "@seluxit/wappsto-porcelain";
import { TFunction } from "i18next";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Trans } from "react-i18next";
import { useTranslation } from "../../translations";

const useAddPermission = (notification?: Dto.NotificationOutput) => {
  const { tC } = useTranslation();
  const isAddMethod = notification?.custom?.method?.[0] === "add";
  const [id, setId] = useState<string>("");
  const {
    sendAsync: permissionRetrieve,
    item: permissionItem,
    error: retrieveError,
  } = Permission.useRetrieve();
  const {
    sendAsync: permissionUpdate,
    loading,
    response,
    error,
  } = Permission.useUpdate();

  const shareItem = useCallback(() => {
    if (!notification) {
      return;
    }

    if (!permissionItem || typeof permissionItem === "string") {
      return;
    }
    const create = permissionItem?.installation?.[0].create || [];

    if (notification.custom?.collection) {
      permissionUpdate(permissionItem.meta.id, permissionItem.meta.id, {
        restriction: [
          {
            create: [...create, notification.custom.collection as string],
            method: {
              retrieve: true,
              update: true,
              create: true,
              delete: false,
            },
          },
        ],
      });
    }
  }, [notification, permissionItem, permissionUpdate]);

  useEffect(() => {
    if (
      !isAddMethod ||
      !notification ||
      !notification.base.ids ||
      permissionItem ||
      retrieveError
    ) {
      return;
    }

    setId(notification.base.ids[0]);
    permissionRetrieve(notification.base.ids[0], notification.base.ids[0]);
  }, [
    permissionRetrieve,
    notification,
    permissionItem,
    retrieveError,
    isAddMethod,
  ]);

  const context = useMemo(() => {
    return (
      <p>
        <Trans
          t={tC as unknown as TFunction<string, string>}
          i18nKey={"precise_permission.add_permission"}
          values={{
            type: notification?.custom?.collection,
          }}
        />
      </p>
    );
  }, [notification?.custom?.collection, tC]);

  return {
    id,
    context,
    shareItem,
    response,
    error,
    loading,
  };
};

export default useAddPermission;
