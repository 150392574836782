import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  KeyOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { Dto } from "@seluxit/wappsto-porcelain";
import {
  Avatar,
  Button,
  List,
  Popconfirm,
  Space,
  Spin,
  Typography,
} from "antd";
import { TFunction } from "i18next";
import React from "react";
import { Trans } from "react-i18next";
import { useTranslation } from "../../../../translations";
import Timestamp from "../../../components/helpers/Timestamp";
import useDeleteNotification from "../hooks/useDeleteNotification";
import { SUPPORTED_CODES } from "../util/params";

type GenericNotificationType = {
  notification: Dto.NotificationOutput;
  title?: string;
  description?: string | React.ReactElement;
  extra?: React.ReactNode;
  onClick: () => void;
  icon?: React.ReactNode;
};

const GenericNotification = React.memo(
  ({
    notification,
    title,
    description,
    extra,
    onClick,
    icon,
  }: GenericNotificationType) => {
    const { tC, t } = useTranslation("features", "notifications");
    const { deleteNotification, loading: isDeleting } = useDeleteNotification();

    const sendDeleteNotification = () => {
      deleteNotification(notification);
    };

    let avatarColor;
    let avatarIcon;
    let altTitle;
    switch (notification.read) {
      case "unread":
        switch (notification.base.type) {
          case "permission":
            avatarIcon = <KeyOutlined />;
            break;
          default:
            avatarColor = "#0094f9";
            avatarIcon = <InfoCircleOutlined />;
        }
        break;
      case "read":
        switch (notification.base.type) {
          case "permission":
            avatarIcon = <KeyOutlined />;
            break;
          default:
            avatarColor = "#aaa";
            avatarIcon = <InfoCircleOutlined />;
        }
        break;
      case "accepted":
        avatarColor = "green";
        avatarIcon = <CheckCircleOutlined />;
        break;
      case "denied":
        avatarColor = "red";
        avatarIcon = <StopOutlined />;
        break;
      case "deleted":
        avatarColor = "red";
        avatarIcon = <ExclamationCircleOutlined />;
        break;
      default:
        avatarColor = "#aaa";
        avatarIcon = <InfoCircleOutlined />;
    }
    switch (notification.base.type) {
      case "wallet":
        altTitle = tC("fromType.wallet");
        break;
      default:
        altTitle = tC("fromType.wappsto");
    }

    return (
      <List.Item key={notification.meta.id} onClick={onClick}>
        <List.Item.Meta
          avatar={
            icon ||
            (isDeleting ? (
              <Spin />
            ) : (
              <Avatar
                style={{ backgroundColor: avatarColor }}
                icon={avatarIcon}
              />
            ))
          }
          title={
            <Typography.Text strong={notification.read === "unread"}>
              {description ||
                (SUPPORTED_CODES.includes(notification.base.code || 0) && (
                  <Trans
                    t={t as unknown as TFunction<string, string>}
                    i18nKey={`baseCode.${notification.base.code}`}
                    values={notification}
                  />
                ))}
            </Typography.Text>
          }
          description={
            <Space direction="vertical">
              {extra && <div>{extra}</div>}

              {title !== null && (
                <Typography.Text
                  type={
                    notification.read === "unread" ? undefined : "secondary"
                  }
                >
                  {title || altTitle}
                </Typography.Text>
              )}
            </Space>
          }
        />
        <Space direction="vertical" align="end">
          <Popconfirm
            title={tC("notificationDeleteConfirm")}
            okText={tC("notificationDeleteButton")}
            okType="danger"
            cancelText={tC("cancelButton")}
            onConfirm={sendDeleteNotification}
            overlayStyle={{ zIndex: 9999 }}
            placement="bottom"
          >
            <Button size="small" type="default">
              {tC("dismissButton")}
            </Button>
          </Popconfirm>
          <Timestamp
            timestamp={notification.timestamp}
            fromNow
            className="font-sm"
          />
        </Space>
      </List.Item>
    );
  }
);

GenericNotification.displayName = "GenericNotification";

export default GenericNotification;
