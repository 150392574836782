import { Dto } from "@seluxit/wappsto-porcelain";
import { Button, Collapse, Modal, Space, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import ShowError from "../../core/components/alerts/ShowError";
import useNotificationStatus from "../../core/features/notifications/hooks/useNotificationStatus";
import { useTranslation } from "../../translations";
import useAddPermission from "./AddPermission";
import useRetrievePermission from "./RetrievePermission";

export type LimitationType = {
  comparator: string;
  attribute: string;
  value: string[];
  type: string;
};

export type PrecisePermissionRequestType = {
  type: string;
  quantity: number | "all";
  new_limitation: Record<string, LimitationType[]>;
  method: string[];
  message: string;
  option: Record<string, string>;
  name_installation: string;
};

type PrecisePermissionRequestModalType = {
  notification: Dto.NotificationOutput | undefined;
  close: () => void;
};

const PrecisePermissionRequestModal = ({
  notification,
  close,
}: PrecisePermissionRequestModalType) => {
  const {
    id: retrieveID,
    context: retrievePermissionContext,
    contextHolder,
    shareItem: shareRetrievePermission,
    loading: retrieveLoading,
    response: retrieveResponse,
    error: retrieveError,
  } = useRetrievePermission(
    notification,
    () => {
      setShow(true);
    },
    close
  );
  const {
    id: addID,
    context: addPermissionContext,
    shareItem: shareAddPermission,
    loading: addLoading,
    response: addResponse,
    error: addError,
  } = useAddPermission(notification);
  const [show, setShow] = useState(false);

  const { t, tC } = useTranslation();
  const isAddMethod = notification?.custom?.method?.[0] === "add";
  const id = retrieveID || addID;
  const { updateNotificationStatus } = useNotificationStatus();

  const shareItem = useCallback(() => {
    if (!notification) {
      return;
    }

    if (isAddMethod) {
      shareAddPermission();
    } else {
      shareRetrievePermission();
    }

    updateNotificationStatus(notification, "accepted");
  }, [
    notification,
    isAddMethod,
    updateNotificationStatus,
    shareAddPermission,
    shareRetrievePermission,
  ]);

  useEffect(() => {
    if (retrieveResponse || addResponse) {
      close();
    }
  }, [retrieveResponse, close, addResponse]);

  const cancel = useCallback(() => {
    setShow(false);
    close();
  }, [close]);

  return (
    <>
      {contextHolder}
      <Modal
        width={"800px"}
        title={t(
          `features:notifications.appNotifications.permissions.precisePermissions.${"requestTitle"}`,
          {
            name: notification?.custom?.name_installation,
          }
        )}
        open={show}
        onCancel={close}
        footer={null}
        destroyOnClose
      >
        {isAddMethod ? addPermissionContext : retrievePermissionContext}

        <ShowError
          error={retrieveError || addError}
          description={
            <>
              <Collapse ghost>
                <Collapse.Panel
                  key={"error_message"}
                  header={tC(
                    "features:notifications.appNotifications.permissions.precisePermissions.errorMessage"
                  )}
                >
                  <Typography.Text type="secondary" key={id}>
                    {id}
                  </Typography.Text>
                </Collapse.Panel>
              </Collapse>
            </>
          }
        />
        <Space style={{ width: "100%", justifyContent: "flex-end" }}>
          <Button type="default" onClick={cancel}>
            {tC(
              "features:notifications.appNotifications.permissions.declineButton"
            )}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            onClick={shareItem}
            loading={retrieveLoading || addLoading}
          >
            {tC(
              "features:notifications.appNotifications.permissions.allowButton"
            )}
          </Button>
        </Space>
      </Modal>
    </>
  );
};

export default PrecisePermissionRequestModal;
