export const DROPDOWN_TRIGGER = ["click"];

export const TYPE_IDS = [
  "permission_group",
  "network",
  "device",
  "value",
  "installation",
  "notification",
];

export const SUPPORTED_CODES = [
  // === ADMIN
  // 1100026, // admin notification
  // 1100027, // User transfer concluded

  // === WALLET
  1100036, // You used 75% of your points
  1100037, // You used 100% of your points
  // 1100038, // Invoice is coming
  1100039, // Your subscription has been changed
  1100040, // One of your payment has failed
  1100041, // Payment has been successful

  // === PERMISSION
  1100004, // Permission added
  1100006, // Permission removed
  // 1100008, // Ownership assigned
  11000013, // Permission updated
  // 604000000, // Manufacturer assigned

  // === PERMISSION GROUP
  // 605000001, // User has been added to a permission group
  // 605000002, // User has been removed from a permission group

  // === INSTALLATION
  1100011, // Notification from installation (custom message)
  // 1100028, // Notification from a background application
  // 1100029, // Installation has been removed

  // === INSTALLATION REQUEST
  1100002, // Request generic permissions
  1100003, // Request precise permission
  // 1100031, // Set up extsync true
  // 1100032, // Permit installation to send email
  // 1100033, // Permit installation to send sms
];
