import { Dto } from "@seluxit/wappsto-porcelain";
import { Button, Modal, Space } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "../translations";

type BillingModalType = {
  notification: Dto.NotificationOutput | undefined;
  close: () => void;
};

const BillingModal = ({ notification, close }: BillingModalType) => {
  const { tC } = useTranslation();

  const goToBilling = useCallback(() => {
    const host = window.location.host.split(".");
    host.shift();
    host.shift();
    const link = `${window.location.protocol}//${host.join(".")}/pricing`;
    window.location.href = link;
  }, []);

  return (
    <>
      <Modal
        width={"800px"}
        title={tC("runner.billing.title")}
        open={true}
        onCancel={close}
        footer={null}
        destroyOnClose
      >
        <p>{notification?.base.action}</p>
        <Space style={{ width: "100%", justifyContent: "flex-end" }}>
          <Button type="primary" htmlType="submit" onClick={goToBilling}>
            {tC("runner.billing.button")}
          </Button>
        </Space>
      </Modal>
    </>
  );
};

export default BillingModal;
