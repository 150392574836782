import { Icon } from "@seluxit/wappsto-porcelain";
import { useEffect, useState } from "react";

const useGetImage = (id: string | undefined) => {
  const [image, setImage] = useState<string | undefined>();
  const { sendAsync, item, loading, error } = Icon.useRetrieveBinary();

  useEffect(() => {
    if (id) {
      sendAsync(id);
    }
  }, [id, sendAsync]);

  useEffect(() => {
    if (item) {
      setImage(
        URL.createObjectURL(
          new Blob([new Uint8Array(item, 0, item.byteLength)]),
        ),
      );
    }
  }, [item]);

  return { image, loading, error };
};

export default useGetImage;
