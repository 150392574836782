import { useVisible } from "@seluxit/wappsto-porcelain";
import React from "react";
import AuthenticationModal from "./components/AuthenticationModal";
import LoginButton from "./components/LoginButton";

type AuthenticationType = {
  buttonStyle?: Record<string, string>;
  registrationLink: string;
  termsLink: string;
};

const Authentication = React.memo(
  ({ buttonStyle = {}, registrationLink, termsLink }: AuthenticationType) => {
    const { visible, show, hide } = useVisible(false);

    return (
      <>
        <LoginButton onClick={show} style={buttonStyle} />
        <AuthenticationModal
          registrationLink={registrationLink}
          termsLink={termsLink}
          hide={hide}
          isVisible={visible}
        />
      </>
    );
  }
);

Authentication.displayName = "Authentication";

export default Authentication;
