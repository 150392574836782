import { Dto, HttpError } from "@seluxit/wappsto-porcelain";
import { Button, Card, List, Popconfirm } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "../../../../translations";
import ShowError from "../../../components/alerts/ShowError";
import useClearNotifications from "../hooks/useClearNotifications";
import NotificationsItem from "./NotificationsItem";

type NotificationsMenuType = {
  notifications: Dto.NotificationOutput[];
  error?: HttpError;
  hasMore: boolean;
  loadMore: () => void;
  filter?: Record<string, string>;
};

const NotificationsMenu = React.memo(
  ({
    notifications,
    error,
    hasMore,
    loadMore,
    filter,
  }: NotificationsMenuType) => {
    const { tC } = useTranslation("features", "notifications");
    const { clearNotifications, loading } = useClearNotifications();

    const clearDisabled = loading || !notifications.length;

    const clearAll = useCallback(() => {
      clearNotifications(filter);
    }, [clearNotifications, filter]);

    let loadMoreClick;
    if (hasMore) {
      loadMoreClick = loadMore;
    }

    return (
      <Card
        style={{ width: "430px" }}
        size="small"
        title={tC("notification", { count: 2 })}
        extra={
          <Popconfirm
            title={tC("notificationsDeleteConfirm")}
            okText={tC("notificationsDeleteButton")}
            okType="danger"
            cancelText={tC("cancelButton")}
            onConfirm={clearAll}
            disabled={clearDisabled}
            overlayStyle={{ zIndex: 9999 }}
            placement="bottom"
          >
            <Button disabled={clearDisabled} type="link">
              {tC("clearAllButton")}
            </Button>
          </Popconfirm>
        }
      >
        <List
          size="small"
          className="notification-list"
          locale={
            error
              ? {
                  emptyText: <ShowError error={error} />,
                }
              : undefined
          }
          loading={loading}
          itemLayout="horizontal"
          dataSource={notifications}
          footer={
            <Button
              key="loadMore"
              disabled={!hasMore}
              onClick={loadMoreClick}
              type="link"
              block
            >
              {tC("loadMoreButton")}
            </Button>
          }
          rowKey={(notification) => notification.meta.id}
          renderItem={(notification) => (
            <NotificationsItem
              key={notification.meta.id}
              notification={notification}
            />
          )}
        />
      </Card>
    );
  }
);

NotificationsMenu.displayName = "NotificationsMenu";

export default NotificationsMenu;
