import { BaseHooks, Dto, HasMeta, HttpError } from "@seluxit/wappsto-porcelain";
import {
  Button,
  Checkbox,
  Collapse,
  Input,
  Radio,
  RadioChangeEvent,
  Space,
  Switch,
  Table,
  Typography,
} from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "../../../../../translations";
import ShowError from "../../../../components/alerts/ShowError";
import NetworkConnectionStatus from "../../../../components/helpers/NetworkConnectionStatus";
import useShareNetworks from "../../hooks/useShareNetworks";

const ITEM_ATTRIBUTES = {
  network: ["id", "name"],
  device: [
    "id",
    "name",
    "manufacturer",
    "product",
    "version",
    "serial",
    "protocol",
    "communication",
    "included",
  ],
  value: ["id", "name", "type", "period", "delta", "permission"],
};

type RestrictionType = {
  method: Record<string, boolean>;
};

type ReturnStatusType = {
  all: boolean;
  future: boolean;
  selected: string[];
};

type ReturnErrorType = {
  status: number;
};

type ShareTableType = {
  itemId: string;
  type: string;
  query: Record<string, number | string | string[]>;
  onSuccess: (obj: ReturnStatusType) => void;
  onError: (error: ReturnErrorType) => void;
  minSelected: number | "all";
  restriction: RestrictionType;
};

function getId(item: string | HasMeta) {
  if (typeof item === "string") {
    return item;
  }
  return item.meta.id;
}

const ShareTable = React.memo(
  ({
    itemId,
    type,
    query,
    onSuccess,
    onError,
    minSelected = 1,
    restriction = { method: {} },
  }: ShareTableType) => {
    const { tC, t } = useTranslation(
      "features",
      "notifications.appNotifications.permissions"
    );
    const filter = useRef(["name"]);
    const filterInput = useRef("");
    const { share, status, errorIds, futureError, allError } =
      useShareNetworks(itemId);
    const error =
      status === "error" ? ({ status } as unknown as HttpError) : undefined;

    const [searchFilter, setSearchFilter] = useState<Record<string, string>>(
      {}
    );

    const pageSize = 5;
    const {
      sendAsync: sendACL,
      items: aclItems,
      loading: aclLoading,
      error: aclError,
    } = BaseHooks(type).useList();

    useEffect(() => {
      sendACL({
        filter: Object.entries({
          ...query,
          from_last: true,
          shared_with: itemId,
        }),
      });
    }, [itemId, query, sendACL]);

    const {
      sendAsync: sendGetData,
      items: dataItems,
      loading: dataLoading,
      error: dataError,
      loadPage,
      total,
      ids,
    } = BaseHooks(type).useList();

    const setPage = useCallback(
      (page: number) => {
        loadPage(page);
      },
      [loadPage]
    );

    useEffect(() => {
      sendGetData({
        expand: 1,
        limit: pageSize,
        filter: Object.entries({
          ...searchFilter,
          ...query,
          from_last: true,
        }),
      });
    }, [query, searchFilter, sendGetData]);

    const loading = aclLoading || dataLoading;
    const isSaving = false;
    const errorRequest = aclError || dataError;
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [all, setAll] = useState(false);
    const [future, setFuture] = useState(false);
    const addDisabled =
      (!all && selectedRows.length === 0) || loading || isSaving;

    // Update selected from acl
    useEffect(() => {
      if (aclItems && !all) {
        setSelectedRows((s) => [
          ...s,
          ...aclItems.map((i: string | HasMeta) => getId(i)),
        ]);
      }
    }, [aclItems, all]);

    const getFilterObj = () => {
      const obj: Record<string, string> = {};
      filter.current.forEach((attribute) => {
        obj[attribute] = filterInput.current;
      });
      return obj;
    };

    const columns = useMemo(() => {
      const col: {
        title: string;
        dataIndex: string | string[];
        render?: (event: unknown) => JSX.Element | string;
      }[] = [
        {
          title: tC("precisePermissions.name"),
          dataIndex: ["meta", "name_by_user"],
        },
        {
          title: tC("precisePermissions.id"),
          dataIndex: ["meta", "id"],
        },
      ];
      if (type === "network") {
        col.push({
          title: tC("precisePermissions.connection"),
          dataIndex: ["meta"],
          render: (meta) => (
            <NetworkConnectionStatus value={meta as Dto.MetaOutput} />
          ),
        });
        col.push({
          title: tC("precisePermissions.created"),
          dataIndex: ["meta", "created"],
          render: (date) => new Date(date as Date).toLocaleString(),
        });
      }
      if (type === "value") {
        col.push({
          title: tC("precisePermissions.valueType"),
          dataIndex: "type",
        });
      }
      return col;
    }, [tC, type]);

    const onFilterChange = (e: RadioChangeEvent) => {
      filter.current = [e.target.value];
    };

    const onSearch = useCallback((text: string) => {
      // Constructing url
      filterInput.current = text;
      const tmpFilter: Record<string, string> = {};
      if (text) {
        filter.current.forEach((f) => {
          if (f === "id") {
            tmpFilter["this_meta.id"] = text;
          } else if (f === "name") {
            tmpFilter["this_meta.name_by_user"] = text;
          } else {
            tmpFilter[`this_${f.replace(/ /g, "_")}`] = text;
          }
        });
      }
      setSearchFilter(tmpFilter);
    }, []);

    const onSelectedRowsChange = (rows: React.Key[]) => {
      setAll(false);
      setSelectedRows(rows.map((k) => k.toString()));
    };

    const shareNetworks = () => {
      if ((!all && selectedRows.length === 0) || !ids) {
        onError({ status: 0 });
        return;
      }
      share(
        all ? ids : selectedRows,
        future,
        type,
        getFilterObj(),
        restriction
      );
    };

    useEffect(() => {
      if (status === "success") {
        onSuccess({ all, future, selected: selectedRows });
      }
    }, [all, future, onSuccess, selectedRows, status]);

    const onAllChange = (e: CheckboxChangeEvent) => {
      setAll(e.target.checked);
      setSelectedRows([]);
    };

    const onFutureChange = useCallback((checked: boolean) => {
      setFuture(checked);
    }, []);

    //useSubscribe(type, itemIds);

    /*useEffect(() => {
      if (shareStatus === "success") {
        message.open({
          type: "success",
          content: tC("precisePermissions.successMessage"),
        });
        if (addItem && refreshList) {
          if (all || selectedRows.length > 10) {
            refreshList();
          } else {
            selectedRows.forEach((id) => {
              const matchedNetwork = items.find(
                (network) => network.meta.id === id
              );
              if (matchedNetwork) {
                addItem(matchedNetwork);
              }
            });
          }
        }
        if (onSuccess) {
          let selected;
          if (all) {
            selected = getFilterObj();
          } else {
            selected = selectedRows;
          }
          onSuccess({ all, future, selected });
        }
      } else if (shareStatus === "error" && onError) {
        onError(error);
      }
    }, [
      addItem,
      all,
      error,
      future,
      message,
      onError,
      onSuccess,
      refreshList,
      selectedRows,
      shareStatus,
      tC,
    ]);*/

    return (
      <>
        {minSelected === "all" && (
          <Space direction="vertical" style={{ marginBottom: 20 }}>
            <Checkbox
              onChange={onAllChange}
              checked={all}
              disabled={loading || isSaving}
            >
              {tC("precisePermissions.selectAll")}
            </Checkbox>
            {all && (
              <Space>
                <Typography.Text>
                  {tC("precisePermissions.selectFuture", { type })}
                </Typography.Text>
                <Switch
                  onChange={onFutureChange}
                  checkedChildren={t("yes")}
                  unCheckedChildren={t("no")}
                  checked={future}
                  disabled={loading || isSaving}
                />
              </Space>
            )}
          </Space>
        )}
        <Space
          style={{ width: "100%", justifyContent: "center", marginBottom: 12 }}
        >
          <Radio.Group
            options={ITEM_ATTRIBUTES.network.map((key) => ({
              label: tC(`precisePermissions.${key}`),
              value: key,
            }))}
            defaultValue="name"
            optionType="button"
            size="small"
            disabled={all}
            onChange={onFilterChange}
          />
          <Input.Search
            size="small"
            disabled={all}
            placeholder={tC("precisePermissions.searchHere")}
            style={{ width: 200 }}
            onSearch={onSearch}
          />
        </Space>
        <Table
          size="small"
          loading={loading}
          columns={columns}
          dataSource={(dataItems as HasMeta[]) || undefined}
          pagination={{
            onChange: setPage,
            pageSize,
            total: total,
            disabled: all,
            position: ["bottomCenter"],
            showSizeChanger: false,
            hideOnSinglePage: true,
          }}
          style={{ marginBottom: 30 }}
          rowKey={(network) =>
            typeof network === "string" ? network : network.meta.id
          }
          locale={
            errorRequest && { emptyText: <ShowError error={errorRequest} /> }
          }
          rowSelection={{
            preserveSelectedRowKeys: true,
            selectedRowKeys: selectedRows,
            onChange: onSelectedRowsChange,
            getCheckboxProps: (item) => ({
              disabled:
                dataItems?.includes(
                  typeof item === "string" ? item : item.meta.id
                ) ||
                isSaving ||
                all,
            }),
          }}
        />
        <ShowError
          error={error}
          description={
            !allError ? (
              <>
                {futureError && (
                  <div>{tC("precisePermissions.addFutureNetworksError")}</div>
                )}
                <Collapse ghost>
                  <Collapse.Panel
                    key={"error_message"}
                    header={tC("precisePermissions.errorMessage")}
                  >
                    {errorIds.map((id) => (
                      <Typography.Text type="secondary" key={id}>
                        {id}
                      </Typography.Text>
                    ))}
                  </Collapse.Panel>
                </Collapse>
              </>
            ) : null
          }
        />
        <Space style={{ width: "100%", justifyContent: "flex-end" }}>
          <Button
            type="primary"
            htmlType="submit"
            onClick={shareNetworks}
            loading={isSaving}
            disabled={addDisabled}
          >
            {tC("precisePermissions.addButton")}
          </Button>
        </Space>
      </>
    );
  }
);

ShareTable.displayName = "ShareTable";

export default ShareTable;
