import { WarningOutlined } from "@ant-design/icons";
import { Space } from "antd";
import React from "react";
import { useTranslation } from "../translations";

const InstallationMissing = React.memo(() => {
  const { tC } = useTranslation();

  return (
    <Space size={"large"}>
      <WarningOutlined style={{ color: "red" }} />
      {tC("runner.installationMissing")}
    </Space>
  );
});

InstallationMissing.displayName = "InstallationMissing";

export default InstallationMissing;
