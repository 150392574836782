import { FirebaseOptions, initializeApp } from "firebase/app";
import {
  Auth,
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
} from "firebase/auth";
import React, { useEffect, useMemo, useRef, useState } from "react";
import getConfigFile from "../../../configFiles";

type FirebaseUIAuthType = {
  uiCallback: (widget: unknown) => void;
  fbSignIn: (response: unknown) => void;
};

function FirebaseUIAuth({ uiCallback, fbSignIn }: FirebaseUIAuthType) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [firebaseUI, setFirebaseUI] = useState<any | null>(null);
  const [userSignedIn, setUserSignedIn] = useState(false);
  const elementRef = useRef(null);
  const [firebaseAuth, setFirebaseAuth] = useState<Auth | null>(null);

  useEffect(() => {
    getConfigFile("firebase").then((file) => {
      const firebaseApp = initializeApp(file as FirebaseOptions);
      setFirebaseAuth(getAuth(firebaseApp));
    });
  });

  // Configure FirebaseUI.
  const uiConfig = useMemo(
    () => ({
      // Popup sign in flow rather than redirect flow.
      signInFlow: "popup",
      // We will display Google and Facebook as auth providers.
      signInOptions: [
        {
          provider: GoogleAuthProvider.PROVIDER_ID,
          scopes: ["email"],
        },
        {
          provider: FacebookAuthProvider.PROVIDER_ID,
          scopes: ["email"],
        },
        {
          provider: "apple.com",
          scopes: ["email"],
        },
      ],
      callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: fbSignIn,
      },
    }),
    [fbSignIn]
  );

  useEffect(() => {
    // Firebase UI only works on the Client. So we're loading the package only after
    // the component has mounted, so that this works when doing server-side rendering.
    /* eslint-disable-next-line @typescript-eslint/no-var-requires */
    setFirebaseUI(require("firebaseui"));
  }, []);

  useEffect(() => {
    if (firebaseUI === null || firebaseAuth === null) {
      return;
    }

    // Get or Create a firebaseUI instance.
    const firebaseUiWidget =
      firebaseUI.auth.AuthUI.getInstance() ||
      new firebaseUI.auth.AuthUI(firebaseAuth);

    if (uiConfig.signInFlow === "popup") {
      firebaseUiWidget.reset();
    }

    // We track the auth state to reset firebaseUi if the user signs out.
    const unregisterAuthObserver = onAuthStateChanged(firebaseAuth, (user) => {
      if (!user && userSignedIn) {
        firebaseUiWidget.reset();
      }
      setUserSignedIn(!!user);
    });

    // Trigger the callback if any was set.
    if (uiCallback) {
      uiCallback(firebaseUiWidget);
    }

    // Render the firebaseUi Widget.
    firebaseUiWidget.start(elementRef.current, uiConfig);

    unregisterAuthObserver();
    firebaseUiWidget.reset();
  }, [firebaseUI, userSignedIn, uiCallback, uiConfig, firebaseAuth]);

  if (firebaseUI !== null) {
    // Import the css only on the client.
    /* eslint-disable-next-line global-require */
    require("firebaseui/dist/firebaseui.css");
  }

  return <div ref={elementRef} />;
}

export default FirebaseUIAuth;
