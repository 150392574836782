import { Dto, Notification, useMounted } from "@seluxit/wappsto-porcelain";
import { message } from "antd";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "../../../../translations";
import { getRequestErrorMessage } from "../../../util/helpers";

const useDeleteNotification = () => {
  const { tC } = useTranslation("features", "notifications");
  const { sendAsync, loading, error } = Notification.useDelete();
  const mounted = useMounted();

  const showError = useCallback(() => {
    if (!error) {
      return;
    }

    const { message: errorMessage } = getRequestErrorMessage(error, tC);
    message.open({
      key: `notificationDelete_${error.config.url}`,
      type: "error",
      content: (
        <>
          <div>{tC("notification:notificationDelete.error")}</div>
          <div>{errorMessage}</div>
        </>
      ),
    });
  }, [error, tC]);

  const deleteNotification = useCallback(
    (notification: Dto.NotificationOutput) => {
      sendAsync(notification.meta.id);
    },
    [sendAsync]
  );

  useEffect(() => {
    if (error && mounted) {
      showError();
    }
  }, [error, mounted, showError]);

  return { deleteNotification, loading, error };
};

export default useDeleteNotification;
