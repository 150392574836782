import { useTranslation as usePorcelainTranslation } from "@seluxit/wappsto-porcelain";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {
  initReactI18next,
  useTranslation as useI18NextTranslation,
} from "react-i18next";
import helpers from "../core/components/helpers/locales";
import authentication from "../core/features/authentication/locales";
import notification from "../core/features/notifications/locales";
import requestPasswordRecovery from "../core/features/requestPasswordRecovery/locales";
import dataModel from "./dataModel";
import launcher from "./launcher";

export type TranslateReturnType = (
  str?: string | undefined,
  options?: Record<string, string | number> | undefined,
) => string;

const SUPPORTED_LNGS = ["en", "da", "de", "es", "ca"];
const missingTranslations: Record<string, Record<string, boolean>> = {};
const RESOURCES = SUPPORTED_LNGS.reduce(
  (a, v) => ({
    ...a,
    [v]: {
      components: {
        helpers: helpers[v],
      },
      features: {
        authentication: authentication[v],
        notifications: notification[v],
        requestPasswordRecovery: requestPasswordRecovery[v],
      },
      dataModel: dataModel[v],
      launcher: launcher[v],
    },
  }),
  {},
);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // i18next-browser-languagedetector
    detection: {
      caches: ["cookie"],
      convertDetectedLanguage: (lng) => lng.replace("-", "_"),
    },

    // i18next
    fallbackLng: "en",
    debug: false,
    supportedLngs: SUPPORTED_LNGS,
    ns: ["launcher"],
    interpolation: {
      escapeValue: false,
    },
    saveMissing: true,
    appendNamespaceToMissingKey: true,
    resources: RESOURCES,

    missingKeyHandler(langs, ns, key) {
      if (process.env.NODE_ENV === "development") {
        langs.forEach((lng) => {
          if (!missingTranslations[lng]) {
            missingTranslations[lng] = {};
          }
          missingTranslations[lng][`${ns}:${key}`] = true;
          /* eslint-disable-next-line no-console */
          console.error(`MISSING TRANSLATION `, missingTranslations);
          console.error("RESOURCES", RESOURCES);
        });
      }
    },
  });

function useTranslation(ns?: string, keyPrefix?: string) {
  const { t: trans } = useI18NextTranslation(ns, { keyPrefix });
  const { t, tC, tCE, tU } = usePorcelainTranslation(trans);

  return { t, tC, tCE, tU };
}

export { i18n, useTranslation };
