import { useCallback, useEffect, useMemo } from "react";
import { makeId } from "../utils/helpers";

const useHistoryReplace = () => {
  const nonce = useMemo(() => {
    return makeId();
  }, []);

  const runner = useMemo(() => {
    return `${window.location.protocol}//${window.location.host.replace(".wapp.", ".runner.").replace(":3050", ":4000")}`;
  }, []);

  const eventHandler = useCallback(
    (event: MessageEvent<{ nonce: string; path: string }>) => {
      if (event.origin === runner) {
        if (event.data.nonce === nonce) {
          try {
            window.history.replaceState(null, "", event.data.path);
          } catch (error) {
            if (process.env.NODE_ENV === "development") {
              console.error(error);
            }
          }
        }
      }
    },
    [],
  );

  useEffect(() => {
    window.addEventListener("message", eventHandler);
    return () => {
      window.removeEventListener("message", eventHandler);
    };
  }, []);

  return { nonce };
};

export default useHistoryReplace;
