import { Dto } from "@seluxit/wappsto-porcelain";
import React from "react";
import PermissionModal from "../core/features/notifications/components/installationPrecisePermission/PermissionModal";

export type LimitationType = {
  comparator: string;
  attribute: string;
  value: string[];
  type: string;
};

export type PermissionRequestType = {
  type: string;
  quantity: number | "all";
  new_limitation: Record<string, LimitationType[]>;
  method: string[];
  message: string;
  option: Record<string, string>;
  name_installation: string;
};

type PermissionRequestModalType = {
  request: Dto.NotificationOutput | undefined;
  show: boolean;
  onClose: () => void;
};

const PermissionRequestModal = ({
  request,
  show,
  onClose,
}: PermissionRequestModalType) => {
  return <PermissionModal show={show} hide={onClose} notification={request} />;
};

export default PermissionRequestModal;
