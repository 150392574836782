import { BellOutlined } from "@ant-design/icons";
import { Badge, Button } from "antd";
import React from "react";

type NotificationsButtonType = {
  count: number;
  onClick: () => void;
};

const NotificationsButton = React.memo(
  ({ count, onClick }: NotificationsButtonType) => (
    <Button
      type="link"
      onClick={onClick}
      icon={
        <Badge count={count} color="pink">
          <BellOutlined
            id="newNotificationIndicator"
            style={{ fontSize: 22, color: "white" }}
          />
        </Badge>
      }
    />
  )
);

NotificationsButton.displayName = "NotificationsButton";

export default NotificationsButton;
