import { Dto } from "@seluxit/wappsto-porcelain";
import React, { useCallback, useState } from "react";
import HeaderBar from "./HeaderBar";
import NotificationHandler from "./NotificationHandler";

type HeaderType = {
  installation?: Dto.InstallationOutput | undefined | null;
  onInstallationDeleted: (state: boolean) => void;
};

const Header = ({ installation, onInstallationDeleted }: HeaderType) => {
  const [showDrawer, setShowDrawer] = useState(false);

  const hideDrawer = useCallback(() => {
    setShowDrawer(false);
  }, []);

  const mouseHandler = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (event.clientY < 100) {
        setShowDrawer(true);
      }
    },
    []
  );

  return (
    <>
      <div
        onMouseMove={mouseHandler}
        style={{
          position: "absolute",
          height: "5px",
          width: "100%",
          zIndex: 999,
        }}
      ></div>

      <HeaderBar
        installation={installation}
        showDrawer={showDrawer}
        onClose={hideDrawer}
      />

      <NotificationHandler installationDeleted={onInstallationDeleted} />
    </>
  );
};

export default Header;
