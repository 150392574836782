import ca from "wappsto-locales/ca/dataModel.json";
import da from "wappsto-locales/da/dataModel.json";
import de from "wappsto-locales/de/dataModel.json";
import en from "wappsto-locales/en/dataModel.json";
import es from "wappsto-locales/es/dataModel.json";
import { JSONValue } from "../utils/types";

const dataModel: Record<string, JSONValue> = { ca, da, en, es, de };

export default dataModel;
