import { Session } from "@seluxit/wappsto-porcelain";
import { Typography } from "antd";
import Cookie from "js-cookie";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "../../../../translations";
import Loader from "../../../components/Loader";
import ShowError from "../../../components/alerts/ShowError";
import FirebaseUIAuth from "./FirebaseUIAuth";

const { Paragraph } = Typography;

type SignInOptionsType = {
  termsLink: string;
};

type fbSignInType = {
  user: {
    accessToken: string;
  };
};

const SignInOptions = React.memo(({ termsLink }: SignInOptionsType) => {
  const [showError, setShowError] = useState(false);
  const { tC } = useTranslation("features", "authentication");
  const text = tC("acceptTermsWhenSignInMessage");
  const terms = tC("acceptTermsWhenSignInTerms");
  const components = text.split(new RegExp(`(${terms})`));
  const { loading, sendAsync, error, response } =
    Session.useSignInWithFirebase();

  const fbSignIn = useCallback(
    (response: unknown) => {
      sendAsync({
        firebase_token: (response as fbSignInType).user.accessToken,
        remember_me: true,
      });
    },
    [sendAsync]
  );

  useEffect(() => {
    if (error) {
      setShowError(true);
    }
  }, [error]);

  const hideError = useCallback(() => {
    setShowError(false);
  }, []);

  useEffect(() => {
    if (response) {
      Cookie.set("sessionID", response.data.meta.id, { expires: 30 });
    }
  }, [response]);

  return (
    <>
      <Paragraph type="secondary">
        {components.map((str: string) => {
          if (str === terms) {
            return (
              <a
                key={str}
                target="_blank"
                rel="noopener noreferrer"
                href={termsLink}
              >
                {str}
              </a>
            );
          }
          return <span key={str}>{str}</span>;
        })}
      </Paragraph>

      {loading ? <Loader /> : null}

      {showError ? <ShowError error={error} /> : null}

      <FirebaseUIAuth uiCallback={hideError} fbSignIn={fbSignIn} />
    </>
  );
});

SignInOptions.displayName = "SignInOptions";

export default SignInOptions;
