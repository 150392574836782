import { CloseCircleOutlined } from "@ant-design/icons";
import { Dto, User } from "@seluxit/wappsto-porcelain";
import { Drawer, Flex, Space, Typography } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Notifications from "../core/features/notifications";
import AppIcon from "./AppIcon";

const styles: React.CSSProperties = {
  fontSize: "large",
  width: 30,
};

type HeaderType = {
  installation?: Dto.InstallationOutput | undefined | null;
  showDrawer: boolean;
  onClose: () => void;
};

const HeaderBar = ({ installation, showDrawer, onClose }: HeaderType) => {
  const [showNotifications, setShowNotification] = useState(false);
  const { sendAsync, item, loading } = User.useMyUser();
  const closeTimeout = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (!item && !loading) {
      sendAsync();
    }
  }, [item, loading, sendAsync]);

  useEffect(() => {
    if (showDrawer) {
      closeTimeout.current = setTimeout(() => {
        setShowNotification(false);
        onClose();
      }, 1000);
    }
  }, [onClose, showDrawer]);

  const mouseLeave = useCallback(() => {
    closeTimeout.current = setTimeout(() => {
      setShowNotification(false);
      onClose();
    }, 300);
  }, [onClose]);

  const mouseCancelClose = useCallback(() => {
    clearTimeout(closeTimeout.current);
  }, []);

  return (
    <Drawer
      placement={"top"}
      closable={false}
      onClose={onClose}
      open={showDrawer}
      height="100px"
      styles={{ body: { color: "white", backgroundColor: "#1f324d" } }}
      key={"top"}
      onMouseOver={mouseCancelClose}
      onMouseLeave={mouseLeave}
      onMouseEnter={mouseCancelClose}
    >
      <Flex justify={"space-between"} align={"center"}>
        <Space>
          <CloseCircleOutlined style={{ ...styles }} onClick={onClose} />
          <AppIcon id={installation?.meta.id} width={52} />
          <Flex vertical>
            <Typography.Text strong style={{ color: "white" }}>
              {installation?.meta.name_by_user} - {installation?.version_app}
            </Typography.Text>
            <Typography.Text style={{ color: "white" }}>
              {installation?.author}&nbsp;
            </Typography.Text>
          </Flex>
        </Space>
        <Space size={"middle"}>
          <Notifications
            setShow={setShowNotification}
            show={showNotifications}
            filter={{ "this_base.from": installation?.meta.id || "" }}
            codes={[1100002, 1100003]}
          />
          <span>{item?.name ? item.name : item?.meta.id}</span>
        </Space>
      </Flex>
    </Drawer>
  );
};

export default HeaderBar;
