import { WarningOutlined } from "@ant-design/icons";
import { Space } from "antd";
import React from "react";
import { useTranslation } from "../translations";

const InstallationDeleted = React.memo(() => {
  const { tC } = useTranslation();

  return (
    <Space size={"large"}>
      <WarningOutlined style={{ color: "red" }} />
      {tC("runner.installation_deleted")}
    </Space>
  );
});

InstallationDeleted.displayName = "InstallationDeleted";

export default InstallationDeleted;
