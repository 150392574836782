import { Dto } from "@seluxit/wappsto-porcelain";
import React from "react";
import useNotificationStatus from "../hooks/useNotificationStatus";
import GenericNotification from "./GenericNotification";
import InstallationMessage from "./InstallationMessage";
import InstallationPermission from "./InstallationPermission";
import SharePermission from "./SharePermission";
import InstallationPrecisePermission from "./installationPrecisePermission";
// import Background from './Background';

const READ = "read";

type NotificationsItemType = {
  notification: Dto.NotificationOutput;
};

const NotificationsItem = React.memo(
  ({ notification }: NotificationsItemType) => {
    const { updateNotificationStatus } = useNotificationStatus();
    if (notification.base === undefined || notification.meta === undefined) {
      return null;
    }

    const onClick = () => {
      updateNotificationStatus(notification, READ);
    };

    switch (notification.base.code) {
      case 1100011:
        return (
          <InstallationMessage notification={notification} onClick={onClick} />
        );

      case 1100002:
        return (
          <InstallationPrecisePermission
            onClick={onClick}
            notification={notification}
          />
        );
      case 1100003:
        return (
          <InstallationPermission
            notification={notification}
            onClick={onClick}
          />
        );
      case 1100004:
        return (
          <SharePermission notification={notification} onClick={onClick} />
        );
      default:
        return (
          <GenericNotification notification={notification} onClick={onClick} />
        );
    }
  }
);

NotificationsItem.displayName = "NotificationsItem";

export default NotificationsItem;
