import { Tooltip, Typography } from "antd";
import React, { MouseEvent } from "react";

const stopPropagation = (event: MouseEvent<HTMLDivElement>) => {
  event.stopPropagation();
};

type IdType = {
  value: string | undefined;
};

function Id({ value }: IdType) {
  return (
    <Tooltip
      placement="bottom"
      title={
        <div role="none" onClick={stopPropagation}>
          {value}
        </div>
      }
    >
      <Typography.Text
        role="none"
        copyable={{ text: value, tooltips: false }}
        onClick={stopPropagation}
      >
        {value}
      </Typography.Text>
    </Tooltip>
  );
}

export default Id;
