import { JSONValue } from "../../../../utils/types";
import ca from "./ca/requestPasswordRecovery.json";
import da from "./da/requestPasswordRecovery.json";
import de from "./de/requestPasswordRecovery.json";
import en from "./en/requestPasswordRecovery.json";
import es from "./es/requestPasswordRecovery.json";

const requestPasswordRecovery: Record<string, JSONValue> = {
  ca,
  da,
  en,
  es,
  de,
};

export default requestPasswordRecovery;
