import { Dto } from "@seluxit/wappsto-porcelain";
import { Badge, Tooltip } from "antd";
import React from "react";
import { useTranslation } from "../../../translations";
import Timestamp from "./Timestamp";

const SUCCESS = "success";
const DEFAULT = "default";

type connectionType = {
  online: boolean;
  timestamp: string;
};

type NetworkConnectionStatusType = {
  value: Dto.MetaOutput | connectionType;
};

function NetworkConnectionStatus({ value }: NetworkConnectionStatusType) {
  const { t, tC } = useTranslation("components", "helpers.networkConnection");

  if (!value) {
    return "-";
  }

  let connection;
  if ("online" in value) {
    if (value.online !== undefined) {
      connection = value;
    }
  } else {
    connection = value.stable_connection || value.connection;
  }

  if (!connection) {
    return "-";
  }

  const status = connection.online
    ? ({
        badgeStatus: SUCCESS,
        text: t("online"),
      } as const)
    : ({
        badgeStatus: DEFAULT,
        text: t("offline"),
      } as const);

  return (
    <Tooltip
      title={
        <Timestamp
          prefix={`${tC("since")} `}
          display="block"
          timestamp={connection.timestamp}
          fromNow
          style={{ color: "white" }}
        />
      }
    >
      <Badge status={status.badgeStatus} text={tC(status.text)} />
    </Tooltip>
  );
}

export default NetworkConnectionStatus;
