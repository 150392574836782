import { Dto } from "@seluxit/wappsto-porcelain";
import { Button } from "antd";
import React, { useCallback, useState } from "react";
import { useTranslation } from "../../../../../translations";
import GenericNotification from "../GenericNotification";
import PermissionModal from "./PermissionModal";

const ACCEPTED = "accepted";
const DELETED = "deleted";

type InstallationPrecisePermissionType = {
  notification?: Dto.NotificationOutput;
  onClick: () => void;
};

const InstallationPrecisePermission = React.memo(
  ({ notification, onClick }: InstallationPrecisePermissionType) => {
    const [show, setShow] = useState(false);
    const { t, tC } = useTranslation(
      "features",
      "notifications.appNotifications.permissions"
    );

    const hide = useCallback(() => {
      setShow(false);
    }, []);

    if (!notification?.base.from) {
      return;
    }

    return (
      <>
        <PermissionModal hide={hide} show={show} notification={notification} />
        <GenericNotification
          description={t(
            `precisePermissions.${
              notification.read === ACCEPTED ? "accepted" : "request"
            }Title`,
            {
              type: notification.custom?.type,
              name: notification.custom?.name_installation,
            }
          )}
          notification={notification}
          onClick={onClick}
          extra={
            notification.read !== DELETED && (
              <Button type="primary" size="small" onClick={() => setShow(true)}>
                {tC("precisePermissions.viewRequestButton")}
              </Button>
            )
          }
        />
      </>
    );
  }
);

InstallationPrecisePermission.displayName = "InstallationPrecisePermission";

export default InstallationPrecisePermission;
