import { Register, useVisible } from "@seluxit/wappsto-porcelain";
import { App, Button, Form, Input, Modal, Typography } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "../../../translations";
import ShowError from "../../components/alerts/ShowError";

const ForgotPassword = React.memo(() => {
  const { tC } = useTranslation("features", "requestPasswordRecovery");
  const { visible, show, hide } = useVisible(false);
  const { loading, sendAsync, error, response } = Register.useRecoverPassword();
  const { modal } = App.useApp();

  const handleSubmit = (values: Record<string, string>) => {
    try {
      if (!loading) {
        sendAsync(values.username);
      }
    } catch (e) {
      // form validation error
    }
  };

  useEffect(() => {
    if (response) {
      modal.success({
        content: tC("emailSentInfo"),
        onOk: hide,
      });
    }
  }, [hide, modal, response, tC]);

  return (
    <>
      <Button type="link" onClick={show}>
        {tC("requestRecoveryButton")}
      </Button>
      <Modal
        title={tC("requestRecoveryTitle")}
        open={visible}
        onCancel={hide}
        footer={null}
        className="modal"
        destroyOnClose
      >
        <Typography.Paragraph type="secondary">
          {tC("requestRecoveryInfo")}
        </Typography.Paragraph>
        <Form name="changePassword" onFinish={handleSubmit} layout="vertical">
          <Form.Item
            label={tC("username")}
            name="username"
            rules={[{ required: true, type: "email" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <ShowError error={error} />
            <Button type="primary" htmlType="submit" loading={loading}>
              {tC("sendButton")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
});

ForgotPassword.displayName = "ForgotPassword";

export default ForgotPassword;
