import {
  Dto,
  Notification,
  Q,
  ReadyState,
  useStream,
} from "@seluxit/wappsto-porcelain";
import { Dropdown, message } from "antd";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "../../../translations";
import { getRequestErrorMessage } from "../../util/helpers";
// import { handleBackgroundNotification } from './components/Background';
import NotificationsButton from "./components/NotificationsButton";
import NotificationsMenu from "./components/NotificationsMenu";
import { SUPPORTED_CODES } from "./util/params";

const NOTIFICATION_COUNT = 5;

type NotificationsType = {
  filter?: Record<string, string>;
  codes?: number[];
  show: boolean;
  setShow: (state: boolean) => void;
};

const Notifications = React.memo(
  ({ filter, codes, show, setShow }: NotificationsType) => {
    const { t, tC } = useTranslation("features", "notifications");
    const [messageApi, contextHolder] = message.useMessage();
    const {
      items: notifications,
      canLoadMore: hasMore,
      loadMore,
      sendAsync,
      error,
    } = Notification.useList();
    const {
      sendAsync: countSend,
      total: totalCount,
      items: countItems,
    } = Notification.useList();
    const { subscribeService, unsubscribeService, readyState } = useStream();

    const FILTER = useMemo(
      () => ({
        "this_base.code": codes || SUPPORTED_CODES,
      }),
      [codes]
    );

    const QUERY = useMemo(
      () => ({
        expand: 1,
        limit: NOTIFICATION_COUNT,
        filter: Object.entries({
          from_last: true,
          order_by: "timestamp",
          ...FILTER,
          ...filter,
        }),
      }),
      [FILTER, filter]
    );

    const doLoadMore = useCallback(() => {
      loadMore(QUERY);
    }, [QUERY, loadMore]);

    useEffect(() => {
      if (notifications === null) {
        sendAsync(QUERY);
      }
    }, [QUERY, notifications, sendAsync]);

    useEffect(() => {
      if (countItems === null) {
        countSend({
          limit: 1000,
          filter: [
            ...Object.entries({ ...FILTER, ...filter }),
            Q.seq("this_read", "unread"),
          ],
        });
      }
    }, [FILTER, countSend, filter, countItems]);

    // subscribe to stream
    useEffect(() => {
      if (readyState !== ReadyState.Unknown) {
        subscribeService("notification");
      }
      return () => {
        unsubscribeService("notification");
      };
    }, [readyState, subscribeService, unsubscribeService]);

    useEffect(() => {
      if (error) {
        const { message: errorMessage } = getRequestErrorMessage(error, tC);
        messageApi.open({
          key: "notificationsLoadMoreError",
          type: "error",
          content: (
            <>
              <div>{t("notificationsLoadMoreError")}</div>
              <div>{errorMessage}</div>
            </>
          ),
        });
      }
    }, [error, messageApi, t, tC]);

    const Menu = (
      <NotificationsMenu
        error={error}
        notifications={(notifications as Dto.NotificationOutput[]) || []}
        hasMore={hasMore}
        loadMore={doLoadMore}
        filter={filter}
      />
    );

    const toggleVisible = useCallback(() => {
      setShow(!show);
    }, [setShow, show]);

    return (
      <>
        <Dropdown
          onOpenChange={setShow}
          open={show}
          trigger={["click"]}
          placement="bottomRight"
          dropdownRender={() => Menu}
        >
          <NotificationsButton
            count={totalCount || 0}
            onClick={toggleVisible}
          />
        </Dropdown>
        {contextHolder}
      </>
    );
  }
);

Notifications.displayName = "Notifications";

export default Notifications;
