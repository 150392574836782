import { Dto, Notification } from "@seluxit/wappsto-porcelain";
import { message } from "antd";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "../../../../translations";
import { getRequestErrorMessage } from "../../../util/helpers";

const useNotificationStatus = () => {
  const { t } = useTranslation("features", "notifications");
  const { sendAsync, error } = Notification.useUpdate();

  useEffect(() => {
    if (!error) {
      return;
    }
    const { message: errorMessage } = getRequestErrorMessage(error, t);
    message.open({
      key: `notificationUpdateError_${error.config.url}`,
      type: "error",
      content: (
        <>
          {t("notificationUpdateError")}
          <div>{errorMessage}</div>
        </>
      ),
    });
  }, [error, t]);

  const updateNotificationStatus = useCallback(
    (
      notification: Dto.NotificationOutput,
      status: "unread" | "read" | "accepted" | "denied" | "deleted",
      data?: Record<string, unknown>
    ) => {
      if (
        status &&
        notification.read !== status &&
        (status !== "read" ||
          (notification.read !== "denied" &&
            notification.read !== "accepted" &&
            notification.read !== "deleted"))
      ) {
        if (data) {
          sendAsync(notification.meta.id, {
            read: status,
            custom: { data },
          });
        } else {
          sendAsync(notification.meta.id, {
            read: status,
          });
        }
      }
    },
    [sendAsync]
  );

  return { updateNotificationStatus };
};

export default useNotificationStatus;
