import { JSONValue } from "../../../../utils/types";
import ca from "./ca/notifications.json";
import da from "./da/notifications.json";
import de from "./de/notifications.json";
import en from "./en/notifications.json";
import es from "./es/notifications.json";

const notifications: Record<string, JSONValue> = { ca, da, en, es, de };

export default notifications;
