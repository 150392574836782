import { JSONValue } from "../../../../utils/types";
import ca from "./ca/helpers.json";
import da from "./da/helpers.json";
import de from "./de/helpers.json";
import en from "./en/helpers.json";
import es from "./es/helpers.json";

const helpers: Record<string, JSONValue> = { ca, da, en, es, de };

export default helpers;
