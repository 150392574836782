import { Dto, HasMeta, useEntityStore } from "@seluxit/wappsto-porcelain";
import { Descriptions, Modal, Tag, Typography } from "antd";
import { TFunction } from "i18next";
import React, { useCallback, useMemo } from "react";
import { Trans } from "react-i18next";
import { useTranslation } from "../../../../../translations";
import useUpdateNotificationStatus from "../../hooks/useNotificationStatus";
import ShareTable from "./ShareTable";

const ACCEPTED = "accepted";
const DELETED = "deleted";

type PermissionModalType = {
  show: boolean;
  hide: () => void;
  notification?: Dto.NotificationOutput;
};

const PermissionModal = React.memo(
  ({ show, hide, notification }: PermissionModalType) => {
    const { store } = useEntityStore();
    const { t, tC } = useTranslation();
    const { updateNotificationStatus } = useUpdateNotificationStatus();

    const restriction = useMemo(() => {
      const method: Record<string, boolean> = {};
      if (
        notification &&
        notification.custom &&
        "method" in notification.custom
      ) {
        (notification.custom.method as string[]).forEach((m: string) => {
          method[m] = true;
        });
      }
      return { method };
    }, [notification]);

    const onSuccess = useCallback(
      (result: { all: boolean; future: boolean; selected: string[] }) => {
        const data: { selected: (HasMeta | string)[] } = {
          ...result,
          selected: [],
        };
        if (result.all) {
          data.selected = result.selected;
        } else {
          result.selected.forEach((id) => {
            const item = store[notification?.custom?.type || ""][id];
            if (!item || !item.data) {
              data.selected.push({
                meta: { id, version: "2.1", type: "unknown" },
              });
            } else {
              data.selected.push(item.data);
            }
          });
        }
        if (notification) {
          updateNotificationStatus(notification, ACCEPTED, data);
        }
        hide();
      },
      [hide, notification, store, updateNotificationStatus]
    );

    const onError = useCallback(
      (error: { status: number }) => {
        if (notification && (error.status === 404 || error.status === 401)) {
          updateNotificationStatus(notification, DELETED);
        }
        hide();
      },
      [notification, hide, updateNotificationStatus]
    );

    const query = useMemo(() => {
      const queryObj: Record<string, string | number | string[]> = {};
      if (!notification || !notification.custom?.new_limitation) {
        return queryObj;
      }

      Object.values(notification.custom?.new_limitation).forEach(
        (limitations) => {
          limitations.forEach((limitation) => {
            const type = limitation.type || "this";
            const key = `${type}_${limitation.attribute}${limitation.comparator.replace("=", "")}`;
            queryObj[key] = limitation.value;
          });
        }
      );

      return queryObj;
    }, [notification]);

    const showRestrictions = () => {
      const r: JSX.Element[] = [];
      if (restriction.method) {
        Object.keys(restriction.method).forEach((method) => {
          if (restriction.method[method]) {
            r.push(
              <Tag color="blue" key={method}>
                {t(
                  `features:notifications.appNotifications.permissions.accessMethods.${method}`
                )}
              </Tag>
            );
          }
        });
      }
      return r;
    };

    const items = [
      {
        key: "requirements",
        label: (
          <Trans
            t={t as unknown as TFunction<string, string>}
            i18nKey={
              "features:notifications.appNotifications.permissions.precisePermissions.minimumItemsRequiredToSelect"
            }
            values={{
              number: notification?.custom?.quantity,
              type: notification?.custom?.type,
            }}
          />
        ),
        children: (
          <Typography.Text strong>
            {notification?.custom?.quantity as string | undefined}
          </Typography.Text>
        ),
      },
      {
        key: "access",
        label: tC(
          "features:notifications.appNotifications.permissions.precisePermissions.access"
        ),
        children: showRestrictions(),
      },
      {
        key: "message",
        label: tC(
          "features:notifications.appNotifications.permissions.precisePermissions.messageFromApp"
        ),
        children: (
          <Typography.Text strong>
            {notification?.custom?.message as string}
          </Typography.Text>
        ),
      },
    ];

    if (!notification?.base.from) {
      return;
    }

    return (
      <Modal
        width={"1200px"}
        title={t(
          `features:notifications.appNotifications.permissions.precisePermissions.${"requestTitle"}`,
          {
            name: notification.custom?.name_installation,
          }
        )}
        open={show}
        onCancel={hide}
        footer={null}
        destroyOnClose
      >
        <Descriptions
          size="small"
          column={2}
          items={items}
          bordered
          style={{ marginBottom: 30 }}
        />

        <ShareTable
          itemId={notification.base.from}
          type={notification.custom?.type || ""}
          query={query}
          onError={onError}
          onSuccess={onSuccess}
          minSelected={notification.custom?.quantity as number}
          restriction={restriction}
        />
      </Modal>
    );
  }
);

PermissionModal.displayName = "PermissionModal";

export default PermissionModal;
