import { Dto, useSessionId } from "@seluxit/wappsto-porcelain";
import { Card, Typography } from "antd";
import React from "react";
import AppIcon from "./AppIcon";

type InstallationCardType = {
  installation: Dto.InstallationOutput;
};

const appCardStyle: React.CSSProperties = {
  position: "relative",
  margin: "auto",
  width: 65,
  height: 65,
  marginBottom: 3,
  borderRadius: 5,
};

const InstallationCard = ({ installation }: InstallationCardType) => {
  const sessionID = useSessionId();
  const cardDisabled = false;

  const openInstallation = (
    installationID: string,
    sessionID: string | undefined
  ) => {
    let protocol;
    let host;
    if (process.env.NODE_ENV === "development" && process.env.REACT_APP_HOST) {
      const appUrl = process.env.REACT_APP_HOST?.split("://");
      if (appUrl?.length === 2) {
        protocol = `${appUrl[0]}:`;
        [host] = appUrl[1].split("/");
      } else {
        console.error(
          "Failed to get protocol and host from ENV",
          process.env.NODE_ENV,
          process.env.REACT_APP_HOST
        );
        return;
      }
    } else {
      protocol = window.location.protocol;
      host = window.location.host;
    }

    let openedOrigin = `${protocol}//${installationID}.wapp.${host}`;
    if (openedOrigin.includes(".wapp.wapp.")) {
      openedOrigin = openedOrigin.replace(".wapp.wapp.", ".wapp.");
    }
    const win = window.open(openedOrigin, "_blank");

    /*if (win) {
      Object.keys(win).forEach((key) => {
        if (/./.test(key)) {
          win.addEventListener(key.slice(2), (event) => {
            console.log(key, event);
          });
        }
      });
    }*/
    /*
    win?.addEventListener("unload", (event) => {
      console.log("Sending message to", openedOrigin);
      setTimeout(() => {
        win?.postMessage(
          {
            sessionID,
            saveCookie: true,
          },
          openedOrigin
        );
      }, 500);
    });
    win?.addEventListener("loadstart", () => {
      console.log("load started");
    });
*/
    window.addEventListener(
      "message",
      (event: MessageEvent<{ ready: boolean }>) => {
        //console.log("Got message", event);
        if (event.origin !== openedOrigin || event.source !== win) {
          return;
        }
        if (event.data && event.data.ready) {
          win?.postMessage(
            {
              sessionID,
              saveCookie: true,
            },
            openedOrigin
          );
        }
      }
    );
    win?.focus();
  };

  return (
    <>
      <div style={appCardStyle}>
        <Card
          onClick={() =>
            installation.supported_features.includes("foreground") &&
            openInstallation(installation.meta.id, sessionID)
          }
          style={appCardStyle}
          styles={{ body: { padding: 0 } }}
          bordered={false}
          hoverable
        >
          <AppIcon id={installation.icon} />
        </Card>
      </div>
      <Typography.Paragraph
        style={{
          position: "relative",
          margin: "auto",
          textAlign: "center",
        }}
        ellipsis={{ rows: 2 }}
        type={cardDisabled ? "secondary" : undefined}
      >
        {installation.meta.name_by_user}
      </Typography.Paragraph>
    </>
  );
};

export default InstallationCard;
