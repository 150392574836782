import { useRefreshTimestamp } from "@seluxit/wappsto-porcelain";
import { Typography } from "antd";
import { BaseType } from "antd/es/typography/Base";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import React from "react";

const FORMAT = "MMMM DD YYYY, H:mm";
const DISPLAY = "inline-block";

dayjs.extend(relativeTime);

type TimestampType = {
  timestamp: string | undefined;
  prefix?: string;
  display?: string;
  fromNow?: boolean;
  format?: string;
  style?: Record<string, string>;
  type?: BaseType;
  className?: string;
};

function Timestamp({
  timestamp,
  prefix = "",
  display = DISPLAY,
  fromNow = false,
  format = FORMAT,
  style,
  type = "secondary",
  className = "",
}: TimestampType) {
  const dayObj = dayjs(timestamp || "");
  let dateString = "";

  // update timestamp field
  useRefreshTimestamp(timestamp || "");

  if (!dayObj.isValid()) {
    return "-";
  }

  if (fromNow) {
    dateString = dayObj.fromNow();
  } else {
    dateString = dayObj.format(format);
  }

  return (
    <Typography.Text
      type={type}
      style={{ display, ...style }}
      className={className}
    >
      {prefix + dateString}
    </Typography.Text>
  );
}

export default Timestamp;
