import { HttpError } from "@seluxit/wappsto-porcelain";
import { TranslateReturnType } from "../../translations";

export function getRequestErrorMessage(
  error: HttpError,
  t: TranslateReturnType,
) {
  let message;
  if (error.data && error.data.code) {
    message = t(
      `error:${error.data.code}`,
      error.data.data as Record<string, string>,
    );
    if (message === `Error:${error.data.code.toString()}`) {
      message = `${error.data.code}: ${error.data.message}`;
    }
  } else if (error.status) {
    message = t(`error:status.${error.status}`);
  } else {
    message = t("error:noResponse");
  }
  return { message };
}
