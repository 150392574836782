import { Acl, Dto, Request, useMounted } from "@seluxit/wappsto-porcelain";
import { useState } from "react";

type ShareResponseType = {
  ok: boolean;
  status?: number;
  allFailed?: boolean;
  ids?: string[];
};

type RestrictionType = {
  method: Record<string, boolean>;
};

const IDLE = "idle";
const ERROR = "error";
const PENDING = "pending";
const SUCCESS = "success";

const useShareNetworks = (itemId: string) => {
  const isMounted = useMounted();
  const [errorIds, setErrorIds] = useState<string[]>([]);
  const [futureError, setFutureError] = useState(false);
  const [allError, setAllError] = useState(false);
  const [status, setStatus] = useState(IDLE);

  const handlePromises = (
    promises: Promise<ShareResponseType>[],
    resolve: (option: ShareResponseType) => void,
  ) => {
    Promise.all(promises)
      .then((response) => {
        if (isMounted.current) {
          let allFailed = true;
          let ok = true;
          let newStatus: number | undefined;
          response.forEach((r) => {
            if (r.ok) {
              allFailed = false;
            } else {
              ok = false;
              setErrorIds((prev) => [...prev, ...(r.ids || [])]);
              if (!newStatus) {
                newStatus = r.status;
              }
            }
          });
          if (ok) {
            resolve({ ok: true });
          } else {
            resolve({ ok: false, status: newStatus, allFailed });
          }
        }
      })
      .catch(() => {
        if (isMounted.current) {
          resolve({ ok: false });
        }
      });
  };

  const shareIdsToItem = (ids: string[], restriction: RestrictionType) => {
    return new Promise<ShareResponseType>((resolve) => {
      Acl.updateMultiple(
        ids,
        {
          permission: [
            {
              meta: {
                id: itemId,
              },
              restriction: [restriction],
            },
          ],
        },
        {
          filter: Object.entries({
            propagate: true,
          }),
        },
      )
        .then(() => {
          resolve({ ok: true, ids });
        })
        .catch(() => {
          resolve({ ok: false, ids });
        });
    });
  };

  const shareAllIds = (
    url: string,
    offset: number,
    restriction: RestrictionType,
  ) =>
    new Promise<ShareResponseType>((resolve) => {
      Request.get<Dto.IdList>(url, Object.entries({ offset }))
        .then((response) => {
          if (isMounted.current) {
            if (response.status === 200 && response.data) {
              if (response.data.id.length) {
                const promises: Promise<ShareResponseType>[] = [];
                promises.push(shareIdsToItem(response.data.id, restriction));
                const currentItems = offset + response.data.id.length;
                if (currentItems < response.data.count) {
                  promises.push(shareAllIds(url, currentItems, restriction));
                }
                handlePromises(promises, resolve);
              } else {
                resolve({ ok: false, allFailed: true });
              }
            } else {
              resolve({ ok: false, status: response.status, allFailed: true });
            }
          }
        })
        .catch(() => {
          if (isMounted.current) {
            resolve({ ok: false });
          }
        });
    });

  const shareTypeWithFuture = (
    type: string,
    filter: Record<string, string>,
    restriction: RestrictionType,
    applyToOld: boolean,
  ) => {
    return new Promise<ShareResponseType>((resolve) => {
      const URL = "/permission_rule";
      const prFilter: { attribute: string; value: string }[] = [];
      Object.keys(filter).forEach((key) => {
        prFilter.push({ attribute: key, value: filter[key] });
      });
      const body = {
        meta: {
          id: itemId,
        },
        to: [
          {
            type,
            restriction,
            filter: prFilter,
          },
        ],
        apply_to_old: false,
      };

      if (applyToOld) {
        body.apply_to_old = true;
      }
      Request.post(URL, undefined, { body }).then((response) => {
        if (response.status === 200 || response.status === 201) {
          resolve({ ok: true, allFailed: false });
        } else {
          resolve({ ok: false, allFailed: false });
        }
      });
    });
  };

  const share = (
    selectedIds: string | string[],
    future: boolean,
    type: string,
    filter: Record<string, string>,
    restriction: RestrictionType,
  ) => {
    setErrorIds([]);
    setFutureError(false);
    setAllError(false);

    setStatus(PENDING);
    const promises = [];
    if (Array.isArray(selectedIds)) {
      promises.push(shareIdsToItem(selectedIds, restriction));
      if (future) {
        promises.push(shareTypeWithFuture(type, filter, restriction, false));
      }
    } else {
      let url = selectedIds
        .replace(/expand=[0-9]+&?/g, "")
        .replace(/limit=[0-9]+&?/g, "")
        .replace(/offset=[0-9]+&?/g, "")
        .replace(/from_last=true&?/g, "")
        .replace(/verbose=true&?/g, "");
      if (url.endsWith("&")) {
        url = url.slice(0, -1);
      }
      if (future) {
        promises.push(shareTypeWithFuture(type, filter, restriction, true));
      } else {
        promises.push(shareAllIds(url, 0, restriction));
      }
    }
    Promise.all(promises)
      .then((response) => {
        if (isMounted.current) {
          setAllError(true);
          let ok = true;
          response.forEach((r) => {
            if ("ok" in r && r.ok) {
              setAllError(false);
            } else {
              ok = false;
              if ("allFailed" in r && r.allFailed !== undefined) {
                if (!r.allFailed) {
                  setAllError(false);
                }
              } else {
                setFutureError(true);
              }
            }
          });
          if (ok) {
            setStatus(SUCCESS);
          } else {
            setStatus(ERROR);
          }
        }
      })
      .catch(() => {
        if (isMounted.current) {
          setStatus(ERROR);
        }
      });
  };

  return {
    share,
    status,
    futureError,
    allError,
    errorIds,
  };
};

export default useShareNetworks;
