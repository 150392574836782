import { Button } from "antd";
import React from "react";
import { useTranslation } from "../../../../translations";

type LoginButtonType = {
  onClick: () => void;
  style?: Record<string, string>;
};

const LoginButton = React.memo(({ onClick, style = {} }: LoginButtonType) => {
  const { tC } = useTranslation("features", "authentication");

  return (
    <Button onClick={onClick} type="primary" style={style}>
      {tC("loginButton")}
    </Button>
  );
});

LoginButton.displayName = "LoginButton";

export default LoginButton;
