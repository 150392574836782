import {
  DislikeOutlined,
  ExclamationCircleOutlined,
  LikeOutlined,
} from "@ant-design/icons";
import { Dto, Permission } from "@seluxit/wappsto-porcelain";
import { App, Button, Space, Typography } from "antd";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "../../../../translations";
import useUpdateNotificationStatus from "../hooks/useNotificationStatus";
import GenericNotification from "./GenericNotification";

const CREATE = "create";
const DELETE = "delete";
const DELETED = "deleted";
const DENIED = "denied";
const ACCEPTED = "accepted";
//const READ = "read";

type InstallationPermissionType = {
  notification: Dto.NotificationOutput;
  onClick: () => void;
};

const InstallationPermission = React.memo(
  ({ notification, onClick }: InstallationPermissionType) => {
    const { t: tRaw } = useTranslation();
    const { t, tC } = useTranslation(
      "features",
      "notifications.appNotifications.permissions"
    );
    const { updateNotificationStatus } = useUpdateNotificationStatus();
    const { message } = App.useApp();
    const title =
      notification.custom?.title_installation ||
      notification.custom?.name_installation;
    const {
      sendAsync: getPermission,
      loading: getLoading,
      item: permissionRetrieveResponse,
    } = Permission.useRetrieve();
    const {
      sendAsync: sendUpdateACL,
      loading: aclUpdateLoading,
      error,
      response: aclUpdateResponse,
    } = Permission.useUpdate();

    const loading = getLoading || aclUpdateLoading;

    const acceptPermission = (e: React.MouseEvent) => {
      e.stopPropagation();
      if (notification.base.from) {
        getPermission(notification.base.from, notification.base.from, {});
      }
    };

    const updateACL = useCallback(() => {
      if (typeof permissionRetrieveResponse === "string") {
        return;
      }
      const method: Record<string, boolean> = {};
      notification.custom?.method?.forEach((strName) => {
        let name = strName;
        if (name === "add") {
          name = CREATE;
        } else if (name === "delete_not_owned") {
          name = DELETE;
        }
        method[name] = true;
      });
      let create;
      if (method.create) {
        create = permissionRetrieveResponse?.installation?.[0]?.create || [];
        method.retrieve = true;
        method.update = true;
        if (notification.custom?.collection) {
          if (typeof notification.custom?.collection === "string") {
            create.push(notification.custom?.collection);
          } else {
            create.concat(notification.custom?.collection);
          }
        }
      }
      const restriction = {
        create,
        method,
      };
      if (notification.base.from) {
        sendUpdateACL(
          notification.base.from,
          notification.base.from,
          {
            restriction: [restriction],
          },
          Object.entries({
            propagate: true,
          })
        );
      }
    }, [permissionRetrieveResponse, notification, sendUpdateACL]);

    const denyPermission = (e: React.MouseEvent) => {
      e.stopPropagation();
      updateNotificationStatus(notification, DENIED, undefined);
    };

    useEffect(() => {
      if (error) {
        const {
          meta: { id },
        } = notification;

        updateNotificationStatus(notification, DELETED);
        const errorMessage = tC("itemDeleted");

        message.open({
          key: `acceptError_${id}`,
          type: "error",
          content: (
            <>
              {t("acceptError")}
              <Typography.Paragraph type="danger">
                {errorMessage}
              </Typography.Paragraph>
            </>
          ),
        });
      }
    }, [error, message, notification, t, tC, updateNotificationStatus]);

    useEffect(() => {
      if (aclUpdateResponse) {
        updateNotificationStatus(notification, ACCEPTED);
      }
    }, [aclUpdateResponse, notification, updateNotificationStatus]);

    useEffect(() => {
      if (permissionRetrieveResponse) {
        updateACL();
      }
    }, [permissionRetrieveResponse, updateACL]);

    return (
      <GenericNotification
        title={title}
        description={
          <>
            {t("description")}{" "}
            <strong>
              {t(`accessMethods.${notification.custom?.method?.at(0)}`)}{" "}
              {tRaw(`dataModel:${notification.custom?.collection}`)}
            </strong>
            .
          </>
        }
        notification={notification}
        onClick={onClick}
        extra={
          notification.read !== ACCEPTED &&
          notification.read !== DELETED && (
            <Space>
              {notification.read !== DENIED && (
                <Button
                  size="small"
                  icon={<DislikeOutlined />}
                  onClick={denyPermission}
                >
                  {tC("declineButton")}
                </Button>
              )}
              <Button
                size="small"
                type="primary"
                onClick={acceptPermission}
                loading={loading}
                icon={
                  error ? (
                    <ExclamationCircleOutlined style={{ color: "red" }} />
                  ) : (
                    <LikeOutlined />
                  )
                }
              >
                {tC("allowButton")}
              </Button>
            </Space>
          )
        }
      />
    );
  }
);

InstallationPermission.displayName = "InstallationPermission";

export default InstallationPermission;
