import { WarningOutlined } from "@ant-design/icons";
import { Space } from "antd";
import React from "react";
import { useTranslation } from "../translations";

const NativeApplication = React.memo(() => {
  const { tC } = useTranslation();

  return (
    <Space size={"large"}>
      <WarningOutlined style={{ color: "red" }} />
      {tC("runner.nativeApplication")}
    </Space>
  );
});

NativeApplication.displayName = "NativeApplication";

export default NativeApplication;
