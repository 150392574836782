import { HttpError } from "@seluxit/wappsto-porcelain";
import { Alert } from "antd";
import React, { ReactNode } from "react";
import { useTranslation } from "../../../translations";
import { getRequestErrorMessage } from "../../util/helpers";

type ShowErrorType = {
  error?: HttpError;
  description?: string | ReactNode;
  className?: string;
  style?: Record<string, string>;
  skipCodes?: number[];
};

const ShowError = React.memo(
  ({
    error,
    description = null,
    className = "",
    style = {},
    skipCodes = [],
  }: ShowErrorType) => {
    const { tC } = useTranslation();
    if (error && (!error.data || skipCodes.indexOf(error.data.code) === -1)) {
      const { message } = getRequestErrorMessage(error, tC);
      return (
        <Alert
          showIcon
          type="error"
          message={message}
          className={className}
          description={description}
          style={style}
        />
      );
    }
    return null;
  }
);

ShowError.displayName = "ShowError";

export default ShowError;
