import {
  Dto,
  ReadyState,
  StreamEvent,
  useStream,
} from "@seluxit/wappsto-porcelain";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { getIDfromHost } from "../utils/helpers";
import BillingModal from "./BillingModal";
import PermissionRequestModal from "./PermissionRequest";
import PrecisePermissionRequestModal from "./PrecisePermission";

type NotificationHandlerType = {
  installationDeleted: (state: boolean) => void;
};

const NotificationHandler = ({
  installationDeleted,
}: NotificationHandlerType) => {
  const { registerCallback, subscribeService, readyState } = useStream();
  const [showPermission, setShowPermission] = useState(false);
  const [showPrecisePermission, setShowPrecisePermission] = useState(false);
  const [showBilling, setShowBilling] = useState(false);
  const [permissionRequest, setPermissionRequest] = useState<
    Dto.NotificationOutput | undefined
  >();
  const [preciseRequest, setPreciseRequest] = useState<
    Dto.NotificationOutput | undefined
  >();
  const [billingRequest, setBillingRequest] = useState<
    Dto.NotificationOutput | undefined
  >();
  const [notificationQueue, setNotificationQueue] = useState<
    Dto.NotificationOutput[]
  >([]);
  const mutex = useRef(false);
  const id = getIDfromHost();

  const handlePermission = useCallback(
    (data: Dto.NotificationOutput | undefined) => {
      if (data === undefined) {
        mutex.current = false;
        return;
      }
      mutex.current = true;
      switch (data.base.code) {
        case 1100002:
          setPermissionRequest(data);
          setShowPermission(true);
          break;
        case 1100003:
          setPreciseRequest(data);
          setShowPrecisePermission(true);
          break;
        case 1100630:
          setBillingRequest(data);
          setShowBilling(true);
          break;
        default:
          mutex.current = false;
      }
    },
    []
  );

  const handleNotificationQueue = useCallback(() => {
    if (notificationQueue.length === 0) {
      mutex.current = false;
      return;
    }

    setNotificationQueue((prev) => {
      const notification = prev.shift();
      handlePermission(notification);
      return prev;
    });
  }, [handlePermission, notificationQueue]);

  const handleNotification = useCallback(
    (event: StreamEvent<unknown>) => {
      const data = event.data as Dto.NotificationOutput;
      if (data?.base?.from === id && data.read === "unread") {
        if (mutex.current) {
          setNotificationQueue((prev): Dto.NotificationOutput[] => {
            if (!event.data) {
              return prev;
            }
            if (
              prev.findIndex((item) => item.meta.id === data.meta.id) !== -1
            ) {
              return prev;
            }
            return [...prev, data];
          });
        } else {
          handlePermission(data);
        }
      }
    },
    [handlePermission, id]
  );

  const handleInstallationNotification = useCallback(
    (event: StreamEvent<unknown>) => {
      if (event.event === "delete" && event.meta_object?.id === id) {
        installationDeleted(true);
      }
    },
    [id, installationDeleted]
  );

  const closePermission = useCallback(() => {
    setShowPermission(false);
    setPermissionRequest(undefined);
    handleNotificationQueue();
  }, [handleNotificationQueue]);

  const closePrecise = useCallback(() => {
    setShowPrecisePermission(false);
    setPreciseRequest(undefined);
    handleNotificationQueue();
  }, [handleNotificationQueue]);

  const closeBilling = useCallback(() => {
    setShowBilling(false);
    setBillingRequest(undefined);
    handleNotificationQueue();
  }, [handleNotificationQueue]);

  useEffect(() => {
    if (readyState !== ReadyState.Unknown) {
      subscribeService("notification");
      subscribeService("installation", { serviceId: id || "" });
      registerCallback("notification", handleNotification);
      registerCallback("installation", handleInstallationNotification);
    }
  }, [
    handleInstallationNotification,
    handleNotification,
    id,
    readyState,
    registerCallback,
    subscribeService,
  ]);

  if (showPermission) {
    return (
      <PermissionRequestModal
        key={permissionRequest?.meta.id}
        request={permissionRequest}
        show={showPermission}
        onClose={closePermission}
      />
    );
  }

  if (showPrecisePermission) {
    return (
      <PrecisePermissionRequestModal
        key={preciseRequest?.meta.id}
        notification={preciseRequest}
        close={closePrecise}
      />
    );
  }

  if (showBilling) {
    return (
      <BillingModal
        key={billingRequest?.meta.id}
        notification={billingRequest}
        close={closeBilling}
      />
    );
  }

  return null;
};

NotificationHandler.displayName = "NotificationHandler";

export default NotificationHandler;
