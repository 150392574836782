import { defaults } from "@seluxit/wappsto-porcelain";
import * as Sentry from "@sentry/react";
import axios from "axios";
import React, { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import setupWappstoCore from "./core";
import ListInstallations from "./pages/ListInstallations";
import Main from "./pages/Main";
import Redirect from "./pages/Redirect";
import { getIDfromHost } from "./utils/helpers";

defaults.addServiceVersion("default", "2.1");
defaults.enableVerbose();
axios.defaults.baseURL = "/";

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    Component() {
      const id = getIDfromHost();
      if (id) {
        return <Main />;
      }
      return <ListInstallations />;
    },
  },
  {
    path: "/wapp-launcher",
    Component() {
      return <Redirect />;
    },
  },
  {
    path: "/register",
    Component() {
      return <ListInstallations />;
    },
  },
  {
    path: "*",
    Component() {
      return <Main />;
    },
  },
]);

function App() {
  useEffect(() => {
    setupWappstoCore({
      baseUrl: `${window.location.protocol}//${window.location.host}`,
    });
  }, []);

  return <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />;
}

export default App;
