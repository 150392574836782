import { defaults, Session } from "@seluxit/wappsto-porcelain";
import axios from "axios";

type SetupWappstoCoreType = {
  baseUrl?: string;
};

const setupWappstoCore = async ({ baseUrl }: SetupWappstoCoreType) => {
  axios.defaults.baseURL = baseUrl || "https://wappsto.com";

  defaults.disableVerbose();

  await Session.validate();
};

export default setupWappstoCore;
