import { useEffect } from "react";

const Redirect = () => {
  useEffect(() => {
    window.location.replace(
      `${window.location.protocol}//wapp.${window.location.host}`
    );
  }, []);

  return null;
};

export default Redirect;
