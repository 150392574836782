import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Session } from "@seluxit/wappsto-porcelain";
import { Button, Checkbox, Form, Input, Modal, Space } from "antd";
import Cookie from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "../../../../translations";
import ShowError from "../../../components/alerts/ShowError";
import getConfigFile from "../../../configFiles";
import ForgotPassword from "../../requestPasswordRecovery";

type ReCAPTCHAType = {
  key: string;
};

const LoginForm = React.memo(() => {
  const { tC } = useTranslation("features", "authentication");
  const [form] = Form.useForm();
  const [recaptcha, setRecaptcha] = useState<string | null>("");
  const [showRecaptcha, setShowRecaptcha] = useState(false);
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const [showError, setShowError] = useState(false);
  const errorNumber = useRef(0);
  const [recaptchaConfig, setRecaptchaConfig] = useState<ReCAPTCHAType | null>(
    null
  );
  const alwaysShowRecaptcha = errorNumber.current > 2;
  const { loading, sendAsync, response, error } =
    Session.useSignInWithCaptcha();

  useEffect(() => {
    getConfigFile("recaptcha").then((file) => {
      setRecaptchaConfig(file as ReCAPTCHAType);
    });
  });

  const handleSubmit = (values: Record<string, string>, rc?: string) => {
    setShowError(false);

    sendAsync({
      username: values.username,
      password: values.password,
      captcha: rc || recaptcha || "",
      remember_me: !!values.remember,
    });
  };

  useEffect(() => {
    if (response) {
      Session.setSessionID(response.data.meta.id);
      Cookie.set("sessionID", response.data.meta.id, { expires: 30 });
    }
  }, [response]);

  useEffect(() => {
    if (error) {
      if (
        !alwaysShowRecaptcha &&
        !showRecaptcha &&
        error.data?.code === 9900007
      ) {
        setShowRecaptcha(true);
      } else {
        errorNumber.current += 1;
        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
        }
        setShowError(true);
        setRecaptcha("");
      }
    }
  }, [alwaysShowRecaptcha, error, showRecaptcha]);

  const cancelRecaptcha = () => {
    errorNumber.current = 3;
    setShowRecaptcha(false);
  };

  const handleModalRecaptcha = (rc: string | null) => {
    setShowRecaptcha(false);
    setRecaptcha(rc);
    if (rc) {
      handleSubmit(form.getFieldsValue() as Record<string, string>, rc);
    }
  };

  return (
    <>
      <Form
        form={form}
        style={{ maxWidth: "350px", textAlign: "left" }}
        name="login"
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={(values) => handleSubmit(values)}
      >
        <Form.Item
          label={tC("username")}
          name="username"
          rules={[{ required: true, type: "email" }]}
        >
          <Input prefix={<UserOutlined className="site-form-item-icon" />} />
        </Form.Item>
        <Form.Item
          label={tC("password")}
          name="password"
          rules={[{ required: true }]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
          />
        </Form.Item>
        {alwaysShowRecaptcha && !showRecaptcha && error ? (
          <Form.Item style={{ marginTop: "10px" }}>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={recaptchaConfig?.key || ""}
              onChange={setRecaptcha}
            />
          </Form.Item>
        ) : null}
        <Form.Item>
          <Space
            //align="top"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Form.Item name="remember" valuePropName="checked">
              <Checkbox>{tC("rememberMe")}</Checkbox>
            </Form.Item>

            <ForgotPassword />
          </Space>
        </Form.Item>
        {showError ? <ShowError error={error} /> : null}
        <Form.Item>
          <Button
            type="primary"
            size="large"
            block
            htmlType="submit"
            disabled={alwaysShowRecaptcha && !recaptcha}
            loading={loading}
          >
            {tC("loginButton")}
          </Button>
        </Form.Item>
      </Form>

      <Modal
        open={showRecaptcha}
        onCancel={cancelRecaptcha}
        footer={null}
        destroyOnClose
      >
        <ReCAPTCHA
          sitekey={recaptchaConfig?.key || ""}
          onChange={handleModalRecaptcha}
        />
      </Modal>
    </>
  );
});

LoginForm.displayName = "LoginForm";

export default LoginForm;
