import { Acl, BaseHooks, Dto } from "@seluxit/wappsto-porcelain";
import { Descriptions, message, Tag, Typography } from "antd";
import { NoticeType } from "antd/es/message/interface";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Id from "../../core/components/helpers/Id";
import { useTranslation } from "../../translations";

const RetrievePermission = (
  notification: Dto.NotificationOutput | undefined,
  show: () => void,
  close: () => void
) => {
  const isAddMethod = notification?.custom?.method?.[0] === "add";
  const [id, setId] = useState<string>("");
  const [messageApi, contextHolder] = message.useMessage();
  const { t, tC } = useTranslation();

  const { sendAsync, item, loading, error } = BaseHooks(
    notification?.base.type_ids || "empty"
  ).useRetrieve();
  const {
    sendAsync: aclUpdate,
    loading: aclLoading,
    response: aclResponse,
    error: aclError,
  } = Acl.useUpdate();

  const shareItem = useCallback(() => {
    if (!notification) {
      return;
    }

    if (!item || !notification.custom?.method) {
      return;
    }

    const method: Record<string, boolean> = {};
    notification.custom?.method.forEach((m) => {
      method[m] = true;
    });

    aclUpdate(item.meta.id, {
      permission: [
        {
          meta: {
            id: notification.base.from,
          },
          restriction: [{ method }],
        },
      ],
    });
  }, [notification, item, aclUpdate]);

  useEffect(() => {
    if (isAddMethod) {
      return;
    }

    if (notification?.base.ids?.[0] && !loading && !item && !error) {
      setId(notification.base.ids[0]);
      sendAsync(notification.base.ids[0]);
    }
  }, [error, isAddMethod, item, loading, notification, sendAsync]);

  useEffect(() => {
    const key = notification?.base.ids?.[0];
    const duration = 1;
    let type: NoticeType = "info";
    let message = "";

    if (loading) {
      type = "loading";
      message = tC("precise_permission.loading", { type: key });
    } else if (error) {
      type = "error";
      message = tC("precise_permission.error", { error: error });
    } else if (item && item.meta.owner === "unassigned") {
      type = "error";
      message = tC("precise_permission.unassigned", {
        type: item.meta.type,
        id: item.meta.id,
      });
    } else {
      messageApi.destroy(key);
      show();
      return;
    }

    messageApi
      .open({
        content: message,
        type,
        key,
        duration,
      })
      .then(() => {
        if (type === "error") {
          close();
        }
      });
  }, [close, error, item, loading, messageApi, notification, show, tC]);

  const itemDescription = useMemo(() => {
    if (!item) {
      return null;
    }

    switch (item.meta.type) {
      case "state":
        return (item as Dto.StateOutput).type;
      case "value":
        return (item as Dto.ValueOutput).name;
      case "device":
        return (item as Dto.DeviceOutput).name;
      case "network":
        return (item as Dto.NetworkOutput).name;
      default:
        return item.meta.type;
    }
  }, [item]);

  const restriction = useMemo(() => {
    const method: Record<string, boolean> = {};
    if (
      notification &&
      notification.custom &&
      "method" in notification.custom
    ) {
      (notification.custom.method as string[]).forEach((m: string) => {
        method[m] = true;
      });
    }
    return { method };
  }, [notification]);

  const showRestrictions = useMemo(() => {
    const r: JSX.Element[] = [];
    if (restriction.method) {
      Object.keys(restriction.method).forEach((method) => {
        if (restriction.method[method]) {
          r.push(
            <Tag color="blue" key={method}>
              {t(
                `features:notifications.appNotifications.permissions.accessMethods.${method}`
              )}
            </Tag>
          );
        }
      });
    }
    return r;
  }, [restriction.method, t]);

  const items = useMemo(() => {
    return [
      {
        key: "name",
        label: tC("dataModel:name"),
        children: <Typography.Text strong>{itemDescription}</Typography.Text>,
      },
      {
        key: "type",
        label: tC(
          "features:notifications.appNotifications.permissions.precisePermissions.valueType"
        ),
        children: <Typography.Text strong>{item?.meta.type}</Typography.Text>,
      },
      {
        key: "access",
        label: tC(
          "features:notifications.appNotifications.permissions.precisePermissions.access"
        ),
        children: showRestrictions,
      },
      {
        key: "id",
        label: tC("dataModel:uuid"),
        children: (
          <Typography.Text strong>
            <Id value={item?.meta.id}></Id>
          </Typography.Text>
        ),
      },
    ];
  }, [item, itemDescription, showRestrictions, tC]);

  const context = useMemo(() => {
    return (
      <Descriptions
        size="small"
        column={1}
        items={items}
        bordered
        style={{ marginBottom: 30 }}
      />
    );
  }, [items]);

  return {
    id,
    context,
    contextHolder,
    shareItem,
    response: aclResponse,
    loading: aclLoading,
    error: aclError,
  };
};

export default RetrievePermission;
