import { LoadingOutlined } from "@ant-design/icons";
import { Image } from "antd";
import React from "react";
import useGetImage from "../hooks/useGetIcon";
import APP_ICON from "../images/app.png";

const styles: React.CSSProperties = {
  borderRadius: 5,
  width: 65,
  height: 65,
  objectFit: "contain",
};

type AppIconType = {
  id: string | undefined;
  width?: number;
};

const AppIcon = React.memo(({ id, width }: AppIconType) => {
  const { image, loading, error } = useGetImage(id);
  const size = width || 65;

  if (loading) {
    return <LoadingOutlined style={{ ...styles, width: size, height: size }} />;
  }

  if (error || !image) {
    return (
      <Image
        width={size}
        height={size}
        preview={false}
        style={{ ...styles, width: size, height: size }}
        src={APP_ICON}
      />
    );
  }

  return (
    <Image
      width={size}
      height={size}
      preview={false}
      style={{ ...styles, width: size, height: size }}
      src={image}
    />
  );
});

AppIcon.displayName = "AppIcon";

export default AppIcon;
