import { JSONValue } from "../utils/types";
import ca from "./locales/ca/launcher.json";
import da from "./locales/da/launcher.json";
import de from "./locales/de/launcher.json";
import en from "./locales/en/launcher.json";
import es from "./locales/es/launcher.json";

const launcher: Record<string, JSONValue> = { ca, da, en, es, de };

export default launcher;
