import {
  DislikeOutlined,
  ExclamationCircleOutlined,
  LikeOutlined,
} from "@ant-design/icons";
import { BaseHooks, Dto } from "@seluxit/wappsto-porcelain";
import { App, Button, Space, Typography } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "../../../../translations";
import { getRequestErrorMessage } from "../../../util/helpers";
import useUpdateNotificationStatus from "../hooks/useNotificationStatus";
import GenericNotification from "./GenericNotification";

const READ = "read";
const DELETED = "deleted";
const DENIED = "denied";
const ACCEPTED = "accepted";

type SharePermissionType = {
  notification: Dto.NotificationOutput;
  onClick: () => void;
};

const SharePermission = React.memo(
  ({ notification, onClick }: SharePermissionType) => {
    const { tC, t } = useTranslation();
    const { updateNotificationStatus } = useUpdateNotificationStatus();
    const { message } = App.useApp();

    const mainID = notification?.base?.ids?.at(0) || "";
    const { sendAsync, error, loading, item } = BaseHooks(
      notification.base.type_ids || ""
    ).useReclaim();

    const description = tC("features:notifications.sharePermission", {
      user_name: notification.base.from_name,
      type: notification.base.info?.[0].type || "",
      name: notification.base.info?.[0].name || "",
    });

    const acceptNotification = (e: React.MouseEvent) => {
      e.stopPropagation();
      sendAsync(mainID);
    };

    const denyPermission = (e: React.MouseEvent) => {
      e.stopPropagation();
      updateNotificationStatus(notification, DENIED, undefined);
    };

    useEffect(() => {
      if (error) {
        let errorMessage;
        if (error.status === 404 || error.status === 401) {
          updateNotificationStatus(notification, DELETED);
          errorMessage = tC("features:notifications.notificationDeleteError");
        } else {
          updateNotificationStatus(notification, READ);
          const m = getRequestErrorMessage(error, tC);
          errorMessage = m.message;
        }
        message.open({
          key: `notificationAcceptError_${notification.meta.id}`,
          type: "error",
          duration: 5,
          content: (
            <>
              {t("features:notifications.notificationAcceptError")}
              <Typography.Paragraph type="danger">
                {errorMessage}
              </Typography.Paragraph>
            </>
          ),
        });
      }
    }, [error, message, notification, tC, t, updateNotificationStatus]);

    useEffect(() => {
      if (item) {
        updateNotificationStatus(notification, ACCEPTED);
      }
    }, [item, notification, updateNotificationStatus]);

    return (
      <GenericNotification
        description={description}
        notification={notification}
        onClick={onClick}
        extra={
          notification.read !== ACCEPTED &&
          notification.read !== DELETED && (
            <Space style={{ justifyContent: "space-between", width: "100%" }}>
              {notification.read !== DENIED && (
                <Button
                  size="small"
                  icon={<DislikeOutlined />}
                  onClick={denyPermission}
                >
                  {tC("features:notifications.notificationDenyButton")}
                </Button>
              )}
              <Button
                size="small"
                type="primary"
                onClick={acceptNotification}
                loading={loading}
                icon={
                  error ? (
                    <ExclamationCircleOutlined style={{ color: "red" }} />
                  ) : (
                    <LikeOutlined />
                  )
                }
              >
                {tC("features:notifications.notificationAcceptButton")}
              </Button>
            </Space>
          )
        }
      />
    );
  }
);

SharePermission.displayName = "SharePermission";

export default SharePermission;
