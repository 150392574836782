const uuidReg =
  /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i;

export function isUUID(str: string) {
  return uuidReg.test(str);
}

export function getIDfromHost() {
  const arrHost = window.location.hostname.split(".");
  if (isUUID(arrHost[0])) {
    return arrHost[0];
  }
  return null;
}

export function getHost() {
  const arrHost = window.location.hostname.split(".");
  if (isUUID(arrHost[0])) {
    arrHost.shift();
  }
  const hostname = arrHost.join(".");
  const port =
    window.location.port === "80" || window.location.port === "443"
      ? ""
      : `:${window.location.port}`;
  const host = `${window.location.protocol}//${hostname}${port}`;
  return host;
}

export function makeId() {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < 12) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}
