import { Notification } from "@seluxit/wappsto-porcelain";

const useClearNotifications = () => {
  const { sendAsync, loading } = Notification.useClear();

  const clearNotifications = (filter?: Record<string, string>) => {
    if (loading) {
      return;
    }
    sendAsync(filter ? Object.entries(filter) : undefined);
  };

  return { clearNotifications, loading };
};

export default useClearNotifications;
