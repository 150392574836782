import { Button, Col, Divider, Modal, Row, Space } from "antd";
import React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "../../../../translations";
import LogoCircle from "../assets/wappsto-logo-circle.png";
import LoginForm from "./LoginForm";
import SignInOptions from "./SignInOptions";

type AuthenticationModalType = {
  isVisible: boolean;
  hide: () => void;
  registrationLink: string;
  termsLink: string;
};

const AuthenticationModal = React.memo(
  ({
    hide,
    isVisible,
    registrationLink,
    termsLink,
  }: AuthenticationModalType) => {
    const { tC } = useTranslation("features", "authentication");
    const isSmallScreen = window.innerWidth < 992;

    return (
      <Modal
        open={isVisible}
        onCancel={hide}
        title={
          <Space size="large">
            <img alt="Wappsto Logo" width={50} src={LogoCircle} />
            {tC("signInToWappstoTitle")}
          </Space>
        }
        className="modal medium"
        footer={null}
        destroyOnClose
      >
        <Row gutter={[16, 16]}>
          <Col
            //align="middle"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}
          >
            <LoginForm />
            <Button size="large" block type="link">
              <NavLink to={registrationLink}>{tC("registerButton")}</NavLink>
            </Button>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 2 }}
            xl={{ span: 2 }}
            xxl={{ span: 2 }}
          >
            <Divider
              type={isSmallScreen ? "horizontal" : "vertical"}
              style={isSmallScreen ? {} : { height: "100%", margin: "0 30px" }}
            />
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 10 }}
            xl={{ span: 10 }}
            xxl={{ span: 10 }}
            //align="middle"
          >
            <SignInOptions termsLink={termsLink} />
          </Col>
        </Row>
      </Modal>
    );
  }
);

AuthenticationModal.displayName = "AuthenticationModal";

export default AuthenticationModal;
